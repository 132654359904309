/** 权限页面路由 **/
import { Component } from 'vue'
import { RouteRecordRaw } from 'vue-router'

import RouteView from '@/layouts/routeView.vue'

/**
 * 权限
 */
export const Role: RouteRecordRaw = {
  path: 'role',
  name: 'role',
  meta: { title: '权限配置', role: 'Admin.Permission', icon: 'oa_role', fullPath: '/manage/role' },
  component: RouteView,
  redirect: '/manage/role/menu',
  children: [
    {
      path: 'menu',
      name: 'role-menu',
      meta: { icon: 'oa_role_menu', role: 'Admin.Permission', title: '功能配置', fullPath: '/manage/role/menu' },
      component: (): Component => import('@/views/role/menu/index.vue')
    },
    {
      path: 'role',
      name: 'role-role',
      meta: { icon: 'oa_role_menu', role: 'Admin.Permission', title: '角色配置', fullPath: '/manage/role/role' },
      component: (): Component => import('@/views/role/role/index.vue')
    },
    {
      path: 'user',
      name: 'role-user',
      meta: { icon: 'oa_role_menu', role: 'Admin.Permission', title: '用户角色', fullPath: '/manage/role/user' },
      component: (): Component => import('@/views/role/role/userList.vue')
    }
  ]
}
